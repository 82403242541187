import * as actionTypes from '../action.js';
import axios from '../../Axios/axios-details';
import qs from 'qs';



export const authStart = () =>{
    return {
        type:actionTypes.AUTH_START
    };
};

export const authSuccess = (token,canUpdateUser,canUpdateVehicle) =>{
    return {
        type:actionTypes.AUTH_SUCCESS,
        token:token,
      canUpdateUser:canUpdateUser,
      canUpdateVehicle:canUpdateVehicle
    }
}

export const authFailure = (error) =>{
    return {
        type:actionTypes.AUTH_FAILED,
        error:error
    }
}

export const logout=() =>{
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return {
        type:actionTypes.INIT_LOGOUT
    }
}



export const auth = (userId,password) =>{
    //const localStorageService = LocalStorageService.getService();
    return dispatch => {
        dispatch(authStart());
        //console.log(userId,password);
        const authData={
            grant_type:'password',
            client_id:'jadu-android-app',
            username:userId,
            password:password,
        }
        //console.log(authData);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
        }
        axios.post('/oauth/token', qs.stringify(authData), config )
        .then(res => {
            // console.log(res)
            const { data } = res;
            // console.log(data);


          const config1 = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
              Authorization: `Bearer ${data.access_token}`
            },
          }

          axios.post('/idv/get-user', qs.stringify(authData), config1 )
            .then(res1 => {
              console.log(res1);
              localStorage.setItem("cuA",res1.data.username);
              localStorage.setItem('access_token',data.access_token);
              localStorage.setItem('refresh_token',data.refresh_token);
              localStorage.setItem('cUU',res1.data.canEditUsers);
              localStorage.setItem('cUV', res1.data.canEditVehicles);
              dispatch(authSuccess(data.access_token,res1.data.canEditUsers,res1.data.canEditVehicles));
            }).catch(err => {
              dispatch(authFailure("Invalid Credentials"));
              console.log(err);
          });



        }).catch(err =>{
            dispatch(authFailure("Invalid Credentials"));
            console.log(err);

        });

    };
};

export const setAuthRedirect= (path) =>{
    return{
        type:actionTypes.SET_AUTH_REDIRECT_PATH,
        path:path
    }
}

export const authCheckStatus= () =>{
    return dispatch =>{
        const token = localStorage.getItem('access_token');
        let canUpdateUser = localStorage.getItem('cUU');
        if(canUpdateUser!="true")
          canUpdateUser = false;
        let canUpdateVehicle = localStorage.getItem('cUV');
        if(canUpdateVehicle!="true")
          canUpdateVehicle=false;
        if(!token){
            dispatch(logout());
        }
        else{
            dispatch(authSuccess(token,canUpdateUser,canUpdateVehicle));
        }
    }
}

export  default{
    auth, logout, authCheckStatus
} ;
