import * as actionTypes from '../action';
import { updateObj } from '../../shared/utility';

const initialState ={
    token:null,
    loading:false,
    footer:null,
    canUpdateUser:false,
    canUpdateVehicle:false,
    authRedirect: '/admin/dashboard',
}



const reducer = (state = initialState, action) =>{
    switch (action.type){
        case actionTypes.AUTH_START:
            return updateObj(state, {error:null, loading:true});
        case actionTypes.AUTH_SUCCESS:
            return updateObj(state, {token:action.token,canUpdateUser:action.canUpdateUser, canUpdateVehicle:action.canUpdateVehicle, error:null,loading:false});
        case actionTypes.AUTH_FAILED:
            return updateObj(state, {error:action.error, loading:false});
        case actionTypes.INIT_LOGOUT:
            return updateObj(state, {token:null,canUpdateUser:false});
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return updateObj(state, {authRedirect: action.path});
        default:
            return state;
    }
}

export default reducer;
