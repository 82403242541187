import * as actionTypes from '../action';

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VIEW_RESPONSIVE:
      return {...state, sidebarShow:action.value}
    default:
      return state
  }
}

export default changeState;