import * as actionTypes from '../action.js';

export const viewResponsive = (value) =>{
    return {
        type:actionTypes.VIEW_RESPONSIVE,
        value:value
    };
};

export const view = (value) =>{
    return (dispatch) =>{
        dispatch(viewResponsive(value));
    }
}

export  default{
    view
} ;